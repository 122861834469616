import { styled } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, useEffect, type ComponentProps } from 'react';
import type { NavLinkProps, NavLinkRenderProps, To } from 'react-router';
import { Link, NavLink } from 'react-router';
import { useIntersectionObserver } from 'usehooks-ts';

import { usePrefetchRoute } from '@/hooks/usePrefetchRoute';
import { mergeRefs } from '@/lib/utils';

const usePrefetch = (to: To) => {
  const prefetch = usePrefetchRoute();
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  useEffect(() => {
    if (isIntersecting) {
      prefetch(to);
    }
  }, [isIntersecting, prefetch, to]);

  return ref;
};

const StyledLink = styled(Link)(() => ({}));

export type PLinkProps = ComponentProps<typeof StyledLink>;

export const PLink = forwardRef<HTMLAnchorElement, PLinkProps>(
  ({ ...props }, externalRef) => {
    const ref = usePrefetch(props.to);
    return (
      <StyledLink ref={(v) => mergeRefs(ref, externalRef)(v)} {...props} />
    );
  },
);

// Some annoying gymnastics around `styled()` taking control over `className` prop
const StyledNavLink = styled(
  forwardRef<
    HTMLAnchorElement,
    NavLinkProps & {
      className_?: string | ((props: NavLinkRenderProps) => string | undefined);
    }
  >(({ className_, className, ...props }, ref) => (
    <NavLink
      ref={ref}
      {...props}
      className={(props) =>
        clsx(
          typeof className_ === 'function' ? className_(props) : className_,
          className,
        )
      }
    />
  )),
)(() => ({}));

export type PNavLinkProps = Omit<
  ComponentProps<typeof StyledNavLink>,
  'className_'
>;

export const PNavLink = forwardRef<HTMLAnchorElement, PNavLinkProps>(
  ({ className, ...props }, externalRef) => {
    const ref = usePrefetch(props.to);
    return (
      <StyledNavLink
        ref={(v) => mergeRefs(ref, externalRef)(v)}
        {...props}
        className_={className}
      />
    );
  },
);
