import type { RouteObject } from 'react-router';

import routes from '@/assets/constants/routes-no';
import { competitionWinnersQuery } from '@/lib/api/queries';
import { smartLazy } from '@/lib/router-utils';
import { queryClient } from '@/queryClient';

export const advent2024Routes: RouteObject[] = [
  {
    path: routes.ADVENT_2024_COMPETITION,
    loader: async () => {
      // Data is required for the landing page to work
      await queryClient.ensureQueryData(competitionWinnersQuery());
      return null;
    },
    lazy: () => smartLazy(import('./LandingPage/LandingPage')),
  },
  {
    path: routes.ADVENT_2024_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms')),
  },
];
