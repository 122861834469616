import type { RouteObject } from 'react-router';

import routes from '@/assets/constants/routes-no';
import { singleCompetitionWinnersQuery } from '@/lib/api/queries';
import { simpleRace, smartLazy } from '@/lib/router-utils';
import { queryClient } from '@/queryClient';

export const january2024Routes: RouteObject[] = [
  {
    path: routes.JANUARY_2024_COMPETITION,
    loader: () =>
      simpleRace([
        queryClient.prefetchQuery(
          singleCompetitionWinnersQuery({ slug: 'january_2024_giveaway' }),
        ),
      ]),
    lazy: () => smartLazy(import('./LandingPage')),
  },
  {
    path: routes.JANUARY_2024_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms')),
  },
];
