import './assets/styles/index.css';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router/dom';
import { TssCacheProvider } from 'tss-react';

import theme from './assets/styles/theme';
import Loader from './components/Loader';
import { router } from './routing';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <HelmetProvider>
        <CacheProvider value={muiCache}>
          <TssCacheProvider value={tssCache}>
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </TssCacheProvider>
        </CacheProvider>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
