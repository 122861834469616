import type { RouteObject } from 'react-router';

import routes from '@/assets/constants/routes-no';
import { competitionWinnersQuery } from '@/lib/api/queries';
import { simpleRace, smartLazy } from '@/lib/router-utils';
import { queryClient } from '@/queryClient';

export const wakeUp2024Routes: RouteObject[] = [
  {
    path: routes.WAKE_UP_2024_COMPETITION,
    loader: () =>
      simpleRace([queryClient.prefetchQuery(competitionWinnersQuery())]),
    lazy: () => smartLazy(import('./LandingPage/LandingPage')),
  },
  {
    path: routes.WAKE_UP_2024_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms')),
  },
  {
    path: routes.WAKE_UP_PART2_2024_COMPETITION,
    loader: () =>
      simpleRace([queryClient.prefetchQuery(competitionWinnersQuery())]),
    lazy: () => smartLazy(import('./LandingPage/LandingPage2')),
  },
  {
    path: routes.WAKE_UP_PART2_2024_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms2')),
  },
];
