import { Suspense, lazy } from 'react';
import { matchPath, redirect } from 'react-router';

import { withSearchParams } from './utils';

import routes from '@/assets/constants/routes-no';
import Loader from '@/components/Loader';
import { sleep } from '@/services/Utils';
import { canAccessAgentPortal, getIsAnonymous } from '@/services/tokens';

export const ROUTE_AWAIT_TIMEOUT =
  Number(localStorage.getItem('ROUTE_AWAIT_TIMEOUT')) || 150;

/** Waits up to `ROUTE_AWAIT_TIMEOUT` before showing fallback */
export const smartLazy = async (
  module: Promise<{ default: any }>,
  fallback = <Loader />,
) => {
  const timeout = sleep(ROUTE_AWAIT_TIMEOUT);

  const direct = module.then((m) => m.default);

  const Comp = lazy(() => module);
  const withSuspense = () => (
    <Suspense fallback={fallback}>
      <Comp />
    </Suspense>
  );

  return {
    Component: await Promise.race([direct, timeout.then(() => withSuspense)]),
  };
};

export const simpleRace = async (
  promises: Promise<any>[],
  timeout: Promise<any> = sleep(ROUTE_AWAIT_TIMEOUT),
) => {
  await Promise.race([...promises, timeout]);
  return null;
};

/**
 * Creates function that can be used to check if given path is active
 *
 * @param request
 * @returns path matcher
 *
 * @example
 * const isOnPath = pathMatcher(request);
 * const isOnUserDetails = isOnPath('/users/:id');
 */
export const pathMatcher = (request: Request) => (pattern: string) =>
  Boolean(matchPath(pattern, new URL(request.url).pathname));

/**
 * Does not redirect if specified url is currently active.
 */
export const safeRedirect = (
  url: string,
  request: Request,
  options?: { throw?: boolean },
) => {
  const isOnPath = pathMatcher(request);
  if (!isOnPath(url)) {
    const r = redirect(url);
    if (options?.throw) {
      throw r;
    }
    return r;
  }
  return null;
};

/** Redirects to login if user is not authenticated */
export const requireAuth = async (request: Request) => {
  const isAnonymous = await getIsAnonymous();

  if (isAnonymous) {
    const redirectUrl = withSearchParams(routes.MY_SCOOPR_LOGIN, {
      return_to: getReturnUrl(request.url),
    });
    throw safeRedirect(redirectUrl, request) || redirect(routes.ERROR_PAGE);
  }

  return null;
};

export const requireAgentAuth = async (request: Request) => {
  const isAnonymous = await getIsAnonymous();

  if (isAnonymous) {
    const redirectUrl = withSearchParams(routes.AGENT_PORTAL_LOGIN, {
      return_to: getReturnUrl(request.url),
    });
    return safeRedirect(redirectUrl, request, { throw: true });
  }

  const canAccess = await canAccessAgentPortal();

  if (!canAccess) {
    throw redirect(routes.ERROR_PAGE);
  }

  return null;
};

export const getReturnUrl = (currentUrl: string) => {
  const parsed = new URL(currentUrl, location.origin);
  parsed.searchParams.delete('token');
  return parsed.pathname + parsed.search;
};
