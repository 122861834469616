import { Button, Typography, type SxProps, type Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import useBrainSession from '../../services/BrainSession/useBrainSession';
import useTracking from '../../services/Tracking/useTracking';

import type { EVENTS } from '@/lib/analytics/legacyEvents';

const useStyles = makeStyles()(() => ({
  buttonLabel: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0 !important',
  },
}));

export type ActionButtonProps = {
  children?: React.ReactNode;
  label?: string;
  flow?: string;
  step?: string;
  clientKnowledge?: Record<string, any>;
  color?: 'secondary' | 'primary' | 'transparent';
  gaEvent?: keyof typeof EVENTS;
  gaParameters?: Record<string, any>;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  labelClassName?: string;
  [key: string]: any;
  sx?: SxProps<Theme>;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  children = null,
  label = '',
  flow = '',
  step = '',
  clientKnowledge = {},
  color = 'secondary',
  gaEvent,
  gaParameters = {},
  className,
  size = 'large',
  onClick,
  labelClassName,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { toggleChat } = useBrainSession();
  const { addEvent } = useTracking();

  return (
    <Button
      variant="contained"
      size={size}
      className={className}
      color={color === 'transparent' ? 'inherit' : color}
      onClick={() => {
        if (gaEvent) {
          addEvent(gaEvent, gaParameters);
        }
        if (onClick) {
          onClick();
          return;
        }
        // @ts-expect-error
        toggleChat({
          goal: flow,
          step,
          clientKnowledge,
        });
      }}
      {...props}
    >
      <Typography
        component="p"
        variant="button2n"
        className={cx(classes.buttonLabel, labelClassName)}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} /> {children}
      </Typography>
    </Button>
  );
};

export default ActionButton;
