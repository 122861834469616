import { addDays, lightFormat } from 'date-fns';
import { HTTPError } from 'ky';
import * as v from 'valibot';

import routes from '@/assets/constants/routes-no';
import { apiClient } from '@/lib/api/apiClient';
import { withSearchParams } from '@/lib/utils';
import { API } from '@/services/Config';

export const defaultApplicationsUrl = withSearchParams(
  routes.AGENT_PORTAL_APPLICATIONS,
  {
    sort: 'created_at',
    desc: 'true',
    f_created_at: JSON.stringify([
      lightFormat(addDays(new Date(), -14), 'yyyy-MM-dd'),
      null,
    ]),
  },
);

export const automadaClient = apiClient.extend({
  prefixUrl: API.url.replace('api', 'automada'),
  timeout: false,
  credentials: 'include',
});

const ErrorSchema = v.union([
  v.object({
    status: v.literal('error'),
    error: v.object({
      type: v.string(),
      message: v.string(),
    }),
  }),
  v.pipe(
    v.object({
      error: v.string(),
    }),
    v.transform(({ error }) => ({
      status: 'error' as const,
      error: { type: 'UnknownError', message: error },
    })),
  ),
]);

export const parseApiError = async (error: unknown) => {
  if (error instanceof HTTPError) {
    const body = await error.response.text();
    let json;
    try {
      json = JSON.parse(body);
    } catch {
      json = { error: body };
    }
    return v.parse(ErrorSchema, json);
  }
};

export const transformAutomadaErrorIntoError = async (error: unknown) => {
  const parsed = await parseApiError(error);

  if (parsed) {
    const error = new Error(parsed.error.message);
    error.name = parsed.error.type;
    throw error;
  }

  throw error;
};
