import { Suspense, lazy, useState } from 'react';
import { Outlet } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { SIZES } from '../../assets/constants/constants';
import useIsMobile from '../../hooks/IsMobile';
import useIsMyScoopr from '../../hooks/IsMyScoopr';
import useBrainSession from '../../services/BrainSession/useBrainSession';
import { GlobalAlert } from '../GlobalAlert';
import Loader from '../Loader';

import Footer from './_components/Footer';
import MinScooprMenu from './_components/MinScooprMenu';
import SideBar from './_components/SideBar';
import TopBar from './_components/TopBar';

const ChatContainer = lazy(() => import('../Chat/_components/ChatContainer'));

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    width: '100%',

    '--top-bar-height': `${SIZES.topBar.height.small}px`,
    [theme.breakpoints.up(SIZES.navigationChange)]: {
      '--top-bar-height': `${SIZES.topBar.height.large}px`,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    width: '100%',
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

const DashboardLayout = () => {
  const { classes } = useStyles();
  const isMyScoopr = useIsMyScoopr();
  const { isMobile } = useIsMobile();
  const { showChatContainer } = useBrainSession();
  const [isSideBarOpen, setSideBarOpen] = useState(false);

  return (
    <Suspense fallback={<Loader />}>
      {showChatContainer ? (
        <div className={classes.root}>
          <ChatContainer />
        </div>
      ) : (
        <div className={classes.root}>
          <TopBar
            setSideBarOpen={() => setSideBarOpen(!isSideBarOpen)}
            isSideBarOpen={isSideBarOpen}
          />
          <SideBar
            onSideBarClose={() => setSideBarOpen(false)}
            isSideBarOpen={isSideBarOpen}
          />

          <div className={classes.wrapper}>
            <div className={classes.contentContainer} role="main">
              <div className={classes.content}>
                <GlobalAlert />
                <Outlet />
              </div>
            </div>
          </div>
          {isMyScoopr && isMobile ? <MinScooprMenu /> : <Footer />}
        </div>
      )}
    </Suspense>
  );
};

export default DashboardLayout;
