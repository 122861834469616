import type { SxProps } from '@mui/material';
import type { LegacyRef, MutableRefObject, RefCallback } from 'react';
import * as v from 'valibot';

import { getReturnUrl } from './router-utils';

export type WsState =
  | {
      wsStatus: 'COMPLETE';
      wsComplete: true;
      wsError: false;
    }
  | {
      wsStatus: 'DATA_FETCHING_ERROR' | 'PARSING_ERROR' | 'ERROR';
      wsComplete: false;
      wsError: true;
    }
  | {
      wsStatus: string | undefined;
      wsComplete: false;
      wsError: false;
    };

export const getWsStatus = (
  msg: Record<string, any> | null | undefined,
): WsState => {
  const wsStatus: string | undefined = msg?.payload?.status;

  if (wsStatus === 'COMPLETE') {
    return {
      wsStatus: 'COMPLETE',
      wsComplete: true,
      wsError: false,
    };
  }

  if (
    wsStatus === 'DATA_FETCHING_ERROR' ||
    wsStatus === 'PARSING_ERROR' ||
    wsStatus === 'ERROR'
  ) {
    return {
      wsStatus,
      wsComplete: false,
      wsError: true,
    };
  }

  return { wsStatus, wsComplete: false, wsError: false };
};

export type PromiseHash = Record<string, Promise<unknown> | undefined>;

export type AwaitedPromiseHash<Hash extends PromiseHash> = {
  [Key in keyof Hash]: Awaited<Hash[Key]>;
};

/**
 * Get a hash of promises and await them all.
 * Then return the same hash with the resolved values.
 * @example
 * export async function loader({ request }: LoaderFunctionArgs) {
 *   return json(
 *     promiseHash({
 *       user: getUser(request),
 *       posts: getPosts(request),
 *     })
 *   );
 * }
 * @example
 * export async function loader({ request }: LoaderFunctionArgs) {
 *   return json(
 *     promiseHash({
 *       user: getUser(request),
 *       posts: promiseHash({
 *         list: getPosts(request),
 *         comments: promiseHash({
 *           list: getComments(request),
 *           likes: getLikes(request),
 *         }),
 *       }),
 *     })
 *   );
 * }
 */
// Code borrowed from `remix-utils` package
export const promiseHash = async <Hash extends PromiseHash>(
  hash: Hash,
): Promise<AwaitedPromiseHash<Hash>> =>
  Object.fromEntries(
    await Promise.all(
      Object.entries(hash).map(async ([key, promise]) => [key, await promise]),
    ),
  );

export const contextsFromSchemaError = (error: v.ValiError<any>) => {
  const issues = v.flatten(error.issues);
  const contexts: Record<string, any> = {};

  if (issues.root) {
    contexts['Schema Errors (root)'] = issues.root;
  }

  if (issues.nested) {
    contexts['Schema Errors (nested)'] = issues.nested;
  }

  if (issues.other) {
    contexts['Schema Errors (other)'] = issues.other;
  }

  return contexts;
};

export const mergeRefs =
  <T = any>(
    ...refs: Array<MutableRefObject<T> | LegacyRef<T>>
  ): RefCallback<T> =>
  (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };

/**
 * Returns given amount of minutes as milliseconds
 */
export const minutes = (n: number) => n * 60 * 1000;

/**
 * Returns given amount of seconds as milliseconds
 */
export const seconds = (n: number) => n * 1000;

export const mergeSx = (...styles: (SxProps<any> | undefined)[]): SxProps =>
  styles.filter(Boolean).flatMap((s) => (Array.isArray(s) ? s : [s]));

export const withSearchParams = (
  url: string,
  params: Record<string, string> | URLSearchParams,
) => {
  const relative = url.startsWith('/');
  const base = location.href;
  const orig = new URL(url, base);

  const newParams = mergeSearchParams(orig.searchParams, params);

  orig.search = newParams.toString();
  const str = orig.toString();

  return relative ? getReturnUrl(str) : str;
};

export const mergeSearchParams = (
  params1: URLSearchParams | Record<string, string>,
  params2: URLSearchParams | Record<string, string>,
) => {
  const mergedParams = new URLSearchParams(params1);
  params2 = new URLSearchParams(params2);
  params2.forEach((value, key) => {
    mergedParams.set(key, value);
  });
  return mergedParams;
};

export const pluralize = (
  value: number,
  singular: string,
  plural = singular + 's',
) => `${value} ${value === 1 ? singular : plural}`;

export const { format: formatTimestamp } = new Intl.DateTimeFormat('no', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export const formatTerms = (value: number) =>
  pluralize(Math.floor(value / 12), 'year');

// This function exists mainly to keep react compiler from complaining
// It is also a bit shorter so that's nice
export const hardNavigate = (url: string) => {
  window.location.href = url;
};
