import { matchPath } from 'react-router';

import routes from '@/assets/constants/routes-no';
import type { CheckManyConsentsResponse, Me } from '@/lib/api/queries';
import { withSearchParams } from '@/lib/utils';

export type MyScooprRedirectArgs = {
  me: Me;
  consents: CheckManyConsentsResponse;
  currentLocation: Pick<Location, 'pathname' | 'search'>;
};

export const getMyScooprRedirect = ({
  me,
  consents,
  currentLocation,
}: MyScooprRedirectArgs): string | undefined => {
  const getRedirect = () => {
    if (!me.email) {
      return routes.MY_SCOOPR_EMAIL;
    } else if (!me.phone_number) {
      return routes.MY_SCOOPR_PHONE;
    } else if (!consents.all_check) {
      return routes.MY_SCOOPR_CONSENTS;
    }
  };

  const redirectUrl = getRedirect();

  if (redirectUrl && !matchPath(redirectUrl, currentLocation.pathname)) {
    return withSearchParams(
      redirectUrl,
      new URLSearchParams(currentLocation.search),
    );
  }
};
