import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getAlertQuery } from '@/lib/api/queries';

export type GlobalAlertProps = {};

export const GlobalAlert: React.FC<GlobalAlertProps> = () => {
  const { data } = useQuery(getAlertQuery());

  if (!data?.enabled || !data.message) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.accent.red,
        width: '100%',
        py: { xs: 2, md: 3 },
        color: 'white',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: { xs: 1, sm: 2 },
        }}
      >
        <InfoOutlinedIcon
          sx={{
            mt: 0.5,
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
          }}
        />
        <Typography variant="h3n">{data.message}</Typography>
      </Container>
    </Box>
  );
};
