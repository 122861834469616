import * as v from 'valibot';

import { nullishAsOptional, numeric } from '../schema-utils';

const SharedLoanFieldsSchema = v.object({
  scoopr_id: v.string(),
  accountId: nullishAsOptional(v.string()),
  coBorrower: v.string(),
  providerID: v.string(),
  receivedTime: v.string(),
  processedTime: v.string(),
  financialInstitutionId: v.string(),
  financialInstitutionName: v.string(),
});

const RepaymentLoanSchema = v.object({
  type: v.literal('repaymentLoan'),
  originalBalance: numeric(),
  balance: numeric(),
  terms: numeric(),
  nominalInterestRate: numeric(),
  installmentCharges: numeric(),
  installmentChargePeriod: v.string(),
  ...SharedLoanFieldsSchema.entries,
});

export type RepaymentLoan = v.InferOutput<typeof RepaymentLoanSchema>;

const CreditFacilitySchema = v.object({
  type: v.literal('creditFacility'),
  creditLimit: numeric(),
  interestBearingBalance: numeric(),
  nonInterestBearingBalance: numeric(),
  nominalInterestRate: numeric(),
  installmentCharges: numeric(),
  installmentChargePeriod: v.string(),
  ...SharedLoanFieldsSchema.entries,
});

export type CreditFacility = v.InferOutput<typeof CreditFacilitySchema>;

const ChargeCardSchema = v.object({
  type: v.literal('chargeCard'),
  interestBearingBalance: numeric(),
  nonInterestBearingBalance: numeric(),
  ...SharedLoanFieldsSchema.entries,
});

export type ChargeCard = v.InferOutput<typeof ChargeCardSchema>;

export type Loan = RepaymentLoan | CreditFacility | ChargeCard;

export const DebtRegisterDataSchema = v.object({
  customerID: v.string(),
  list: nullishAsOptional(
    v.array(
      v.variant('type', [
        v.object({
          type: v.literal('repaymentLoan'),
          loans: v.array(RepaymentLoanSchema),
        }),
        v.object({
          type: v.literal('creditFacility'),
          loans: v.array(CreditFacilitySchema),
        }),
        v.object({
          type: v.literal('chargeCard'),
          loans: v.array(ChargeCardSchema),
        }),
      ]),
    ),
  ),
});

export type DebtRegisterData = v.InferOutput<typeof DebtRegisterDataSchema>;
